*{ 
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.img-backGround{
  width: 120%;
  height: auto;
}

.img-backGround2{
  margin-left: -100px;
  width: 120%;
  height: auto;
}

.continer{
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  color:rgb(236, 234, 226);
  margin:0px;
  font-family:'Roboto';
}

#container_false {
  opacity: 0;
}
#container {
  opacity: 1;
  color:#fff;
  text-transform: uppercase;
  font-size: 50px;
  font-weight:bold;
  left: 50%;
  padding-top:200px;  
  position:fixed;
  bottom: 50%;
  display:block;
}

#flip {
  height:65px;
}

#flip > div > div {
  color:#fff;
  padding:4px 12px;
  margin-bottom:45px;
  display:inline-block;
}

#flip div:first-child {
  animation: show 6s linear infinite;
}

@keyframes show {
  0% {margin-top:-270px;}
  5% {margin-top:-180px;}
  34% {margin-top:-180px;}
  38% {margin-top:-90px;}
  66% {margin-top:-90px;}
  72% {margin-top:0px;}
  99.99% {margin-top:0px;}
  100% {margin-top:-270px;}
}

p {
  margin-top: 10px;
  font-size: 25px;
  cursor: pointer;
}

.title{
  font-size: 25px;
  padding-bottom: 5px;
  border-bottom: 3px solid white;
}

@media (min-width: 600px) and (max-width: 999px) {
  
  .img-backGround{
    width: 150%;
    height: auto;
  }

  .img-backGround2{
    width: 160%;
    height: auto;
  }

  p {
    margin-top: 10px;
    font-size: 20px;
    cursor: pointer;
  }

  .title{
    font-size: 30px;
    padding-bottom: 5px;
    border-bottom: 3px solid white;
  }

  #container {
    color:#fff;
    text-transform: uppercase;
    font-size: 30px;
    font-weight:bold;
    left: 50%;
    padding-top:200px;  
    position:fixed;
    bottom: 50%;
    display:block;
  }
  
  #flip {
    height:45px;
  }
  
  #flip > div > div {
    color:#fff;
    padding:4px 12px;
    margin-bottom:45px;
    display:inline-block;
  }
  
  #flip div:first-child {
    animation: show 6s linear infinite;
  }
  
  @keyframes show {
    0% {margin-top:-270px;}
    5% {margin-top:-180px;}
    34% {margin-top:-180px;}
    38% {margin-top:-90px;}
    66% {margin-top:-90px;}
    72% {margin-top:0px;}
    99.99% {margin-top:0px;}
    100% {margin-top:-270px;}
  }

}

@media (min-width: 340px) and (max-width: 600px){

  .img-backGround{
    margin-left: -320px;
    width: 300%;
    height: auto;
  }

  .img-backGround2{
    margin-left: -550px;
    width: 340%;
    height: auto;
  }

  p {
    margin-top: 10px;
    font-size: 15px;
    cursor: pointer;
  }

  .title{
    font-size: 20px;
    padding-bottom: 5px;
    border-bottom: 3px solid white;
  }

  #container {
    color:#fff;
    text-transform: uppercase;
    font-size: 30px;
    font-weight:bold;
    left: 20%;
    padding-top:200px;  
    position:fixed;
    bottom: 50%;
    display:block;
  }
  
  #flip {
    height:45px;
  }
  
  #flip > div > div {
    color:#fff;
    padding:4px 12px;
    margin-bottom:45px;
    display:inline-block;
  }
  
  #flip div:first-child {
    animation: show 6s linear infinite;
  }
  
  @keyframes show {
    0% {margin-top:-270px;}
    5% {margin-top:-180px;}
    34% {margin-top:-180px;}
    38% {margin-top:-90px;}
    66% {margin-top:-90px;}
    72% {margin-top:0px;}
    99.99% {margin-top:0px;}
    100% {margin-top:-270px;}
  }
}
