.modalfor5{
  position: fixed;
  top: 260px;
  left: 305px;
  height: 515px;
  z-index: 5;
}

.modal5 {
  position: static;
  display: flex;
  width: 300px;
  background-color: #deb88757;
  opacity: 0;
  transition: 0.7s;
  pointer-events: none;
}

.modal.active5 {
  opacity: 1;
  pointer-events: all;
}

.modal__content5 {
  background-color: #deb88757;
  width: 300px;
  transform: scale(0.5);
  transition: 0.7s all;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__content.active5  {
  transform: scale(1)
}

.list5{
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 20px;
  color: rgb(250, 245, 245)
}

@media (min-width: 600px) and (max-width: 999px) {
  
  .modalfor5{
    position: fixed;
    top: 225px;
    left: 255px;
    height: 530px;
    z-index: 94;
  }
  
  .modal5 {
    position: static;
    display: flex;
    width: 250px;
    background-color: #deb88757;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }
  
  .modal.active5 {
    width: 250px;
    opacity: 1;
    pointer-events: all;
  }
  
  .modal__content5 {
    background-color: #deb88757;
    width: 250px;
    transform: scale(0.5);
    transition: 0.7s all;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal__content.active5  {
    transform: scale(1);
  }
  
  .list5{
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
    color: rgb(250, 245, 245)
  }
}

@media (min-width: 340px) and (max-width: 600px) { 
  .modalfor5{
    position: fixed;
    top: 175px;
    left: 155px;
    height: 495px;
    z-index: 83;
  }
  
  .modal5 {
    position: static;
    display: flex;
    width: 250px;
    background-color: #deb88757;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }
  
  .modal.active5 {
    width: 160px;
    opacity: 1;
    pointer-events: all;
  }
  
  .modal__content5 {
    background-color: #deb88757;
    width: 230px;
    transform: scale(0.5);
    transition: 0.7s all;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal__content.active5  {
    transform: scale(1);
    width: 130px;
  }
  
  .list5{
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 13px;
    color: rgb(250, 245, 245)
  }
}
