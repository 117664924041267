.modalfor4{
  position: fixed;
  top: 210px;
  left: 305px;
  height: 370px;
  z-index: 4;
}

.modal4 {
  position: static;
  display: flex;
  width: 300px;
  background-color: #deb88757;
  opacity: 0;
  transition: 0.7s;
  pointer-events: none;
}

.modal.active4 {
  opacity: 1;
  pointer-events: all;
}

.modal__content4 {
  background-color: #deb88757;
  width: 300px;
  transform: scale(0.5);
  transition: 0.7s all;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__content.active4  {
  transform: scale(1)
}

.list4{
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 20px;
  color: rgb(250, 245, 245)
}

@media (min-width: 600px) and (max-width: 999px) {
  
  .modalfor4{
    position: fixed;
    top: 185px;
    left: 255px;
    height: 360px;
    z-index: 93;
  }
  
  .modal4 {
    position: static;
    display: flex;
    width: 220px;
    background-color: #deb88757;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }
  
  .modal.active4 {
    opacity: 1;
    pointer-events: all;
  }
  
  .modal__content4 {
    background-color: #deb88757;
    width: 200px;
    transform: scale(0.5);
    transition: 0.7s all;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal__content.active4  {
    transform: scale(1)
  }
  
  .list4{
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
    color: rgb(250, 245, 245)
  }
}

@media (min-width: 340px) and (max-width: 600px) { 

  .modalfor4{
    position: fixed;
    top: 140px;
    left: 155px;
    height: 320px;
    z-index: 82;
  }
  
  .modal4 {
    position: static;
    display: flex;
    width: 300px;
    background-color: #deb88757;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }
  
  .modal.active4 {
    opacity: 1;
    pointer-events: all;
  }
  
  .modal__content4 {
    background-color: #deb88757;
    width: 300px;
    transform: scale(0.5);
    transition: 0.7s all;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal__content.active4  {
    transform: scale(1)
  }
  
  .list4{
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 13px;
    color: rgb(250, 245, 245)
  }
}
