* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

nav {
  height: 100%;
  width: 60px;
  background-color: #deb887ad;
  position: fixed;
  z-index: 50;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.menu-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.menu-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.active{
  background-color: #a4c9a7;
  padding: 6px;
}


