.modalfor2{
  position: fixed;
  top: 115px;
  left: 305px;
  height: 250px;
  z-index: 2;
}

.modal2 {
  position: static;
  display: flex;
  width: 300px;
  background-color: #deb88757;
  opacity: 0;
  transition: 0.7s;
  pointer-events: none;
}

.modal.active2 {
  opacity: 1;
  pointer-events: all;
}

.modal__content2 {
  background-color: #deb88757;
  width: 300px;
  transform: scale(0.5);
  transition: 0.7s all;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__content.active2  {
  transform: scale(1)
}

.list2{
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 20px;
  color: rgb(250, 245, 245)
}

@media (min-width: 600px) and (max-width: 999px) {
  .modalfor2{
    position: fixed;
    top: 105px;
    left: 255px;
    height: 230px;
    z-index: 91;
  }
  
  .modal2 {
    position: static;
    display: flex;
    width: 250px;
    background-color: #deb88757;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }
  
  .modal.active2 {
    opacity: 1;
    pointer-events: all;
  }
  
  .modal__content2 {
    background-color: #deb88757;
    width: 220px;
    transform: scale(0.5);
    transition: 0.7s all;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal__content.active2  {
    transform: scale(1)
  }
  
  .list2{
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
    color: rgb(250, 245, 245)
  }
}

@media (min-width: 340px) and (max-width: 600px) {

  .modalfor2{
    position: fixed;
    top: 75px;
    left: 155px;
    height: 190px;
    z-index: 80;
  }
  
  .modal2 {
    position: static;
    display: flex;
    width: 200px;
    background-color: #deb88757;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }
  
  .modal.active2 {
    opacity: 1;
    pointer-events: all;
  }
  
  .modal__content2 {
    background-color: #deb88757;
    width: 180px;
    transform: scale(0.5);
    transition: 0.7s all;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal__content.active2  {
    transform: scale(1)
  }
  
  .list2{
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 12px;
    color: rgb(250, 245, 245)
  }
}
