* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}
.app{
  background-image: url(/src/imageForBackGround/1680x1260.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


main {
  padding: 50px;
}

.img-backGround{
  width: 100%;
  height: auto;
}
.continer{
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  color:rgb(236, 234, 226);
  margin:0px;
  font-family:'Roboto';
  text-align:center;
}

#container {
  color:#999;
  text-transform: uppercase;
  font-size: 25px;
  font-weight:bold;
  left:75%;
  padding-top:200px;  
  position:fixed;
  /* width:100%; */
  bottom: 50%;
  display:block;
  /* overflow: hidden; */
}

#flip {
  height:50px;
  overflow:hidden;
}

#flip > div > div {
  color:#fff;
  padding:4px 12px;
  height: 50px;
  margin-bottom:45px;
  display:inline-block;
}

#flip div:first-child {
  animation: show 7s linear infinite;
}

@keyframes show {
  0% {margin-top:-270px;}
  5% {margin-top:-180px;}
  34% {margin-top:-180px;}
  38% {margin-top:-90px;}
  66% {margin-top:-90px;}
  72% {margin-top:0px;}
  99.99% {margin-top:0px;}
  100% {margin-top:-270px;}
}

