.modal11 {
  position:fixed;
  top: 70%;
  left: 60px;
  z-index: 60;
  display: flex;
  height: 200px;
  width: 50%;
  background-color: #deb8873f;
  opacity: 0;
  transition: 0.7s;
  pointer-events: none;
}

.modal11.active{
  opacity: 1;
  pointer-events: all;
  
}

.modal__content11{
  background-color: #deb887e5;
  width: 95%;
  transform: scale(0.5);
  transition: 0.7s all;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__content11.active {
  transform: scale(1);

}

.text4{
  color: white;
  font-size: 20px;
}

@media (min-width: 880px) and (max-width: 999px) {  
  modal11 {
    position:fixed;
    top: 50%;
    z-index: 100;
    display: flex;
    height: 290px;
    width: 60%;
    background-color: #deb8873f;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }
  
  .text4{
    color: white;
    font-size: 18px;
  }
}

@media (min-width: 600px) and (max-width: 879px) {  

  .modal11 {
    position:fixed;
    top: 70%;
    z-index: 100;
    display: flex;
    height: 200px;
    width: 60%;
    background-color: #deb8873f;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }
  
  .text4{
    color: white;
    font-size: 18px;
  }
}

@media (min-width: 501px) and (max-width: 600px) { 
  .modal11 {
    position:fixed;
    top: 70%;
    z-index: 100;
    display: flex;
    height: 180px;
    width: 80%;
    background-color: #deb8873f;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }
  
  .text4{
    color: white;
    font-size: 18px;
  }
}

@media (min-width: 340px) and (max-width: 500px) { 

  .modal11 {
    position:fixed;
    top: 70%;
    z-index: 100;
    display: flex;
    height: 220px;
    width: 80%;
    background-color: #deb8873f;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }
  
  .text4{
    color: white;
    font-size: 17px;
  }
}
