.modal8{
  position:fixed;
  top: 120px;
  left: 60px;
  z-index: 85;
  display: flex;
  /* left: 30px; */
  height: 290px;
  width: 300px;
  background-color: #deb88725;
  opacity: 0;
  transition: 0.7s;
  pointer-events: none;
}

.modal8.active{
  opacity: 1;
  pointer-events: all;
  
}

.modal__content8{
  background-color: #deb88757;
  width: 300px;
  transform: scale(0.5);
  transition: 0.7s all;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__content8.active {
  transform: scale(1);
}

 /* label color */
 .input-field label {
  color: rgb(26, 18, 18);
}

/* .input-field input[type=text]:focus + label {
  color: rgb(55, 57, 156);
} */

.labelName.active{
  background-color:#deb88700
}

.validate::placeholder {
  color: black;
}