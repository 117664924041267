.modalfor6 {
  position: fixed;
  top: 310px;
  left: 305px;
  height: 560px;
  z-index: 6;
}

.modal6 {
  position: static;
  display: flex;
  width: 300px;
  background-color: #deb88757;
  opacity: 0;
  transition: 0.7s;
  pointer-events: none;
}

.modal.active6 {
  opacity: 1;
  pointer-events: all;
}

.modal__content6 {
  background-color: #deb88757;
  width: 300px;
  transform: scale(0.5);
  transition: 0.7s all;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__content.active6 {
  transform: scale(1)
}

.list6 {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 20px;
  color: rgb(250, 245, 245)
}

@media (min-width: 600px) and (max-width: 999px) { 
  
  .modalfor6 {
    position: fixed;
    top: 265px;
    left: 255px;
    height: 580px;
    z-index: 95;
  }
  
  .modal6 {
    position: static;
    display: flex;
    width: 300px;
    background-color: #deb88757;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }
  
  .modal.active6 {
    opacity: 1;
    pointer-events: all;
  }
  
  .modal__content6 {
    background-color: #deb88757;
    width: 300px;
    transform: scale(0.5);
    transition: 0.7s all;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal__content.active6 {
    transform: scale(1)
  }
  
  .list6 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
    color: rgb(250, 245, 245)
  }
}

@media (min-width: 340px) and (max-width: 600px) {

  .modalfor6 {
    position: fixed;
    top: 205px;
    left: 155px;
    height: 510px;
    z-index: 84;
  }
  
  .modal6 {
    position: static;
    display: flex;
    width: 300px;
    background-color: #deb88757;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }
  
  .modal.active6 {
    opacity: 1;
    pointer-events: all;
  }
  
  .modal__content6 {
    background-color: #deb88757;
    width: 300px;
    transform: scale(0.5);
    transition: 0.7s all;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal__content.active6 {
    transform: scale(1)
  }
  
  .list6 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 13px;
    color: rgb(250, 245, 245)
  }
}
