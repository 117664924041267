.modal9 {
  position:fixed;
  top: 75%;
  left: 60px;
  z-index: 100;
  display: flex;
  height: 85px;
  width: 320px;
  background-color: #deb88725;
  opacity: 0;
  transition: 0.7s;
  pointer-events: none;
}

.modal9.active{
  opacity: 1;
  pointer-events: all;
}

.modal__content9{
  background-color: #deb88757;
  width: 300px;
  transform: scale(0.5);
  transition: 0.7s all;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__content9.active {
  transform: scale(1);

}
.text1{
  color: white;
  font-size: 21px;
}

@media (min-width: 600px) and (max-width: 999px) {  
  
  .modal9 {
    position:fixed;
    top: 75%;
    z-index: 100;
    display: flex;
    width: 320px;
    background-color: #deb88725;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }

  .text1{
    color: white;
    font-size: 18px;
  }

}

@media (min-width: 340px) and (max-width: 600px) { 
  .modal9 {
    position:fixed;
    top: 78%;
    z-index: 100;
    display: flex;
    width: 270px;
    background-color: #deb88725;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }

  .text1{
    color: white;
    font-size: 15px;
  }
}
