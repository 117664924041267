.modal10 {
  position:fixed;
  top: 25%;
  left: 60px;
  z-index: 90;
  display: flex;
  height: 500px;
  width: 500px;
  background-color: #deb88725;
  opacity: 0;
  transition: 0.7s;
  pointer-events: none;
}

.modal10.active{
  opacity: 1;
  pointer-events: all;
  
}

.modal__content10{
  background-color: #deb88757;
  width: 500px;
  transform: scale(0.5);
  transition: 0.7s all;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__content10.active {
  transform: scale(1);
}

nav.awssld__bullets{
  position: absolute;
  height: 40px;
  bottom: -50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.photo {
  max-width: none;
  min-width: auto;
  min-height: auto;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 }
 .awssld__bullets button {
  padding: 0;
  display: block;
  width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: 50%;
  background: var(--control-bullet-color);
  text-indent: -9999px;
  overflow: hidden;
  cursor: pointer;
  border: none;
  transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out;
}

@media (min-width: 600px) and (max-width: 999px) {  
  
  .modal10 {
    position:fixed;
    top: 10%;
    z-index: 100;
    display: flex;
    height: 400px;
    width: 400px;
    background-color: #deb88725;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }
  
  .modal10.active{
    opacity: 1;
    pointer-events: all;
    
  }
  
  .modal__content10{
    background-color: #deb88757;
    width: 380px;
    transform: scale(0.5);
    transition: 0.7s all;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal__content10.active {
    transform: scale(1);
  }
  
  nav.awssld__bullets{
    visibility: hidden;
  }
  .photo {
    max-width: none;
    min-width: auto;
    min-height: auto;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   }
   .awssld__bullets button {
    padding: 0;
    display: block;
    width: 10px;
    height: 10px;
    margin: 5px;
    border-radius: 50%;
    background: var(--control-bullet-color);
    text-indent: -9999px;
    overflow: hidden;
    cursor: pointer;
    border: none;
    transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out;
  }
  nav.awssld__bullets {
    visibility: visible;
}
}

@media (min-width: 340px) and (max-width: 600px) { 
  .modal10 {
    position:fixed;
    top: 35%;
    z-index: 100;
    display: flex;
    height: 300px;
    width: 300px;
    background-color: #deb88725;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }
  nav.awssld__bullets {
    visibility: visible;
}
}

.swiper {
  width: 100%;
  height: 100%;
}
@media (min-width: 340px) and (max-width: 600px) { 
  .swiper {
    height: 500px;
    width: 250px;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-prev, .swiper-button-next { 
  color:bisque;
}
.swiper-pagination-bullet-active {
  background: bisque;
}