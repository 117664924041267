.modalfor1{
  position: fixed;
  left: 305px;
  z-index: 1;
  top: 70px;
  height: 250px;
}

.modal1 {
  position: static;
  display: flex;
  width: 300px;
  background-color: #deb88725;
  opacity: 0;
  transition: 0.7s;
  pointer-events: none;
}

.modal.active1 {
  opacity: 1;
  pointer-events: all;
}

.modal__content1 {
  background-color: #deb88757;
  width: 300px;
  transform: scale(0.5);
  transition: 0.7s all;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__content.active1  {
  transform: scale(1)
}

.list1{
  margin-top: 30px;
  font-size: 20px;
  color: rgb(250, 245, 245)
}

@media (min-width: 600px) and (max-width: 999px) {
  
.modalfor1{
  position: fixed;
  left: 255px;
  z-index: 90;
  top: 65px;
  height: 250px;
}

.modal1 {
  position: static;
  display: flex;
  width: 250px;
  background-color: #deb88725;
  opacity: 0;
  transition: 0.7s;
  pointer-events: none;
}

.modal.active1 {
  opacity: 1;
  pointer-events: all;
}

.modal__content1 {
  background-color: #deb88757;
  width: 220px;
  transform: scale(0.5);
  transition: 0.7s all;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__content.active1  {
  transform: scale(1)
}

.list1{
  margin-top: 30px;
  font-size: 18px;
  color: rgb(250, 245, 245)
}
}

@media (min-width: 340px) and (max-width: 600px) {

  .modalfor1{
    position: fixed;
    left: 155px;
    z-index: 70;
    top: 40px;
    height: 210px;
  }
  
  .modal1 {
    position: static;
    display: flex;
    width: 200px;
    background-color: #deb88725;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }
  
  .modal.active1 {
    opacity: 1;
    pointer-events: all;
  }
  
  .modal__content1 {
    background-color: #deb88757;
    width: 180px;
    transform: scale(0.5);
    transition: 0.7s all;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal__content.active1  {
    transform: scale(1)
  }
  
  .list1{
    margin-top: 30px;
    font-size: 12px;
    color: rgb(250, 245, 245)
  }

}
