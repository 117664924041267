

.mainStyleModal{
  z-index: 80;
}

.modal {
  position: static;
  display: flex;
  height: 100vh;
  width: 300px;
  margin-left: 60px;
  background-color: #deb88725;
  opacity: 0;
  transition: 0.7s;
  pointer-events: none;
}

.modal.active{
  opacity: 1;
  pointer-events: all;
}

.modal__content{
  background-color: #deb88757;
  width: 300px;
  transform: scale(0.5);
  transition: 0.7s all;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__content.active {
  transform: scale(1)
}

p {
  background-image: linear-gradient(to right, #ffffff, #f9fdff 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  /* display: inline-block; */
  /* padding: 5px 0; */
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}
p:before{
  content: '';
	background: #131414;
	display: block;
	position: absolute;
	bottom: -3px;
	left: 0;
	width: 0;
	height: 3px;
	transition: all 0.3s ease-in-out;
}
p:hover{
  background-position: 0;
}
p:hover::before {
  width: 100%;
}

@media (min-width: 600px) and (max-width: 999px) {
  .modal {
    position: static;
    display: flex;
    height: 100vh;
    width: 250px;
    background-color: #deb88725;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }

  .modal__content{
    background-color: #deb88757;
    width: 220px;
    transform: scale(0.5);
    transition: 0.7s all;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 340px) and (max-width: 600px){

  .modal {
    position: static;
    display: flex;
    height: 100vh;
    width: 150px;
    background-color: #deb88725;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
  }

  .modal__content{
    background-color: #deb88757;
    width: 150px;
    transform: scale(0.5);
    transition: 0.7s all;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
}
