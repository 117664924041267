.modal7 {
  position: fixed;
  top: 50px;
  left: 60px;
  display: flex;
  height: 50vh;
  width: 500px;
  background-color: #deb88725;
  opacity: 0;
  transition: 0.7s;
  pointer-events: none;
  z-index: 100;
}

.modal7.active{
  opacity: 1;
  pointer-events: all;
}

.modal__content7{
  background-color: #deb88757;;
  width: 500px;
  transform: scale(0.5);
  transition: 0.7s all;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__content7.active {
  transform: scale(1);
}

@media (min-width: 600px) and (max-width: 999px) { 

  .modal7 {
    position: static;
    position: fixed;
    top: 50px;
    display: flex;
    height: 40vh;
    width: 450px;
    background-color: #deb88725;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
    z-index: 100;
  }
  
  .modal7.active{
    opacity: 1;
    pointer-events: all;
  }
  
  .modal__content7{
    background-color: #deb88757;;
    width: 440px;
    transform: scale(0.5);
    transition: 0.7s all;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  nav.awssld__bullets {
    visibility: visible;
}
}

@media (min-width: 340px) and (max-width: 600px) {

  .modal7 {
    position: fixed;
    top: 50px;
    display: flex;
    height: 500px;
    width: 85vw;
    background-color: #deb88725;
    opacity: 0;
    transition: 0.7s;
    pointer-events: none;
    z-index: 100;
  }
  
  .modal7.active{
    opacity: 1;
    pointer-events: all;
  }
  
  .modal__content7{
    background-color: #deb88757;;
    width: 500px;
    transform: scale(0.5);
    transition: 0.7s all;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}
@media (min-width: 340px) and (max-width: 600px) { 
  .swiper {
    height: 500px;
    width: 250px;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-prev, .swiper-button-next { 
  color:bisque;
}
.swiper-pagination-bullet-active {
  background: bisque;
}